<template>
  <sp-wrapper title="Editarea informatiilor de pe pagina principala">

    <sp-group title="Bannerele butoane de sub slider">
      <sp-item step="1">
        Se acceseaza Panoul de admin
      </sp-item>

      <sp-item step="2">
        Se submeniul meniul Homepage din cadrul meniului principal Setari generale
      </sp-item>

      <sp-item step="3">
        TODO
      </sp-item>
    </sp-group>

  </sp-wrapper>
</template>

<script>
export default {
  name: 'Homepage'
}
</script>
